<template>
    <div class="sidebar">
        <div class="header item">
            <Topic :topic="topic" @selectedTopic="$emit('selectedTopic',topic)"/>
        </div>
        <SubTopic v-for="subtopic in subtopics" :key="subtopic.id" :topic="subtopic" @subtopicSelected="editorOn"/>
        <TopicEditor :topic="selectedTopic" v-if="isEdit" @submit="editorOn"/>

        <button class="addTask" @click="newSub">Add SubTask</button>
    </div>
</template>

<script>
import Topic from "./Topic";
import TopicEditor from "./TopicEditor";
import SubTopic from "./SubTopic";
export default {
    name:'TopicBar',
    components:{Topic,SubTopic,TopicEditor},
    props:{
        topic:Object,
        subtopics:Array
    },
    emits:['selectedTopic'],
    data(){
        return {
            selectedTopic:null,
            isEdit:false
        }
    },
    methods:{
        editorOn(topic){
            console.log('test');
            this.selectedTopic = this.selectedTopic == topic ? null : topic
            this.isEdit = !this.isEdit
        },
        newSub(){
            this.editorOn({
                data:{
                    Parent:this.topic.data.Name,
                    Completion:0
                }
            })
        }

    }
}
</script>

<style scoped>
    .sidebar{
        flex: 0 1 30%;
        display: flex;
        /* justify-content: left; */
        /* align-content: center; */
        align-items: baseline;
        margin-left: 1em;
        flex-direction: column;
        border-right: 1px black bold;
    }
</style>