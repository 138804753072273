<template>
  <NavBar />
  <router-view />
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
</template>

<script>
import NavBar from "./components/NavBar";
export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style>
:root {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
