<template>
    <div class="card"  @click="topicClick" :style="background">
        <span>{{topic.data.Name}}</span>
    </div>    
</template>

<script>
export default {
    props:{
        topic: Object
    },
    methods:{
        topicClick(){
            this.$emit('selectedTopic', this.topic)
        },
        calcRed(){
            let comp = parseInt(this.topic.data.Completion)
            let redVal = this.topic.data.Completion ? 255-255*comp/100 : 255
            return redVal.toFixed(0);

        },
        calcGreen(){
            let comp = parseInt(this.topic.data.Completion)
            let greenVal = this.topic.data.Completion ? 255*comp/100 : 0
            return greenVal.toFixed(0);

        }
    },
    emits:[
        'selectedTopic'
    ],
    computed:{
        background(){
            return `background: linear-gradient(45deg, white 0%, rgba(${this.calcRed()},${this.calcGreen()},0,1) 100%);`
        }
    },
}
</script>

<style scoped>
    .card {
        border: 2px solid #C95536;
        border-radius: 25px;
        background-color: #5536C9;
        /* background: linear-gradient(45deg, rgba(85,54,201,1) 0%, rgba(255,0,0,1) 100%); */
        height: 100px;
        width: 200px;
        font-size: 2em;
        font-weight: bold;
        color: black;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: 10px 10px;
    }

    .card:hover {
        background: #36C955;
    }

</style>
