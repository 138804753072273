<template>
    <div class="subtopic" @click="$emit('subtopicSelected', topic)">
        {{topic.data.Name}}
    </div>
</template>

<script>

export default {
    name:'SubTopic',
    props:{
        topic:Object
    },
    emits:['subtopicSelected']

}
</script>

<style scoped>
    .subtopic  {
        font-size: 1.5em;
        text-decoration: underline;
        width: 100%;
        display: flex;
        justify-content: left;
        margin-left: 20px;
    }
</style>