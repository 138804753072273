<template>
  <!-- <h1>CheatSheets</h1> -->
  <div class="card-frame" v-if="selectedTopic == null">
    <Topic
      v-for="topic in topics"
      :key="topic.id"
      :topic="topic"
      @selectedTopic="setSelected"
    />
  </div>

  <div class="info" v-if="selectedTopic != null">
    <TopicBar
      :subtopics="subtopics"
      :topic="selectedTopic"
      @selectedTopic="setSelected"
    />
    <TopicInfo :subtopics="subtopics" />
  </div>
</template>

<script>
// import TopicEditor from "../components/TopicEditor";
import Topic from "../components/Topic";
import TopicBar from "../components/TopicBar";
import TopicInfo from "../components/TopicInfo";
export default {
  name: "CheatSheets",
  components: {
    // TopicEditor,
    TopicInfo,
    Topic,
    TopicBar,
  },
  data() {
    return {
      topics: [],
      selectedTopic: null,
      subtopics: null,
    };
  },

  async created() {
    try {
      const res = await fetch("api/cta");
      this.topics = await res.json();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    // Toggle effect on selected
    setSelected(topic) {
      if (this.selectedTopic != topic) {
        this.selectedTopic = this.selectedTopic = topic;
        this.getSubTopics();
      } else {
        this.selectedTopic = null;
      }
    },
    async getSubTopics() {
      let data = await fetch(`/api/cta/${this.selectedTopic.data.Name}`);
      data = await data.json();
      this.subtopics = data;
    },
  },
};
</script>

<style scoped>
.card-frame {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: baseline;
  margin-top: 20px;
}
.info {
  display: flex;
}
</style>
