<template>
  <p>Test</p>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  -ms-grid-column-align: 5px;
}
.connectors {
  grid-column: 1 / 2;
}

.mainpane {
  grid-column: 2/ 3;
}
</style>
