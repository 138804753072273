<template>
  <div class="nav">
    <div class="nav-item">
      <router-link to="CTA">CheatSheets</router-link>
      <router-link to="Blog">Blogs</router-link>
    </div>
    <div class="nav-item icon">
      <router-link to="/">
        <i>🤯</i>
        <img src="/images/esign.png" alt="" />
      </router-link>
    </div>
    <div class="nav-item">
      <router-link to="About">About</router-link>
    </div>
    <!-- <div v-if="!$auth.loading.value">
            <button v-if="!$auth.isAuthenticated.value" @click="login">Log in</button>
            <button v-if="$auth.isAuthenticated.value" @click="logout">Log out</button>
        </div> -->
    <div class="nav-item">
      <div v-if="!$auth.loading.value">
        <h6 v-if="!$auth.isAuthenticated.value" @click="login">Log in</h6>
        <h6 v-if="$auth.isAuthenticated.value" @click="logout">Log out</h6>
      </div>
    </div>
  </div>
  <div class="arrow-down"></div>
</template>

<script>
export default {
  name: "Nav",

  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style>
:root {
  --arrowSize: 20px;
}
.nav {
  background-color: #36c955;
  border-bottom: 2px solid #c95536;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 125px;
  width: 100%;
}
.nav-item {
  font-size: 2em;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
router-link {
  padding: 10px;
}
div a {
  text-decoration: none;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: var(--arrowSize) solid transparent;
  border-right: var(--arrowSize) solid transparent;
  border-top: var(--arrowSize) solid #c95536;
  display: block;
  position: relative;
  left: calc(50% - var(--arrowSize));
}
</style>
