<template>
  <div class="body">
    <div class="subtopic" v-for="subtopic in subtopics" :key="subtopic.id">
      <h1>{{ subtopic.data.Name }}</h1>
      <span v-html="subtopic.data.Body" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TopicInfo",
  components: {},
  props: {
    subtopics: Array,
  },
};
</script>

<style>
.body {
  flex: 1 2 80%;
}
ul {
  text-align: left;
  justify-self: center;
}
</style>
