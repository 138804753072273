<template>
  <form @submit="editTopic" v-if="topic">
    <div class="input-container">
      <label for="Name">Topic Name</label>
      <input id="Name" type="text" v-model="selectedTopic.data.Name" />
    </div>
    <div class="input-container">
      <label for="Name">Topic Progress</label>
      <input
        id="Progress"
        type="number"
        v-model="selectedTopic.data.Completion"
      />
    </div>
    <div class="input-container">
      <label for="Parent">Topic Parent</label>
      <input id="Parent" type="text" v-model="selectedTopic.data.Parent" />
    </div>
    <div class="input-container">
      <label for="body">Body</label>
      <textarea
        name="body"
        id="body"
        cols="60"
        rows="20"
        v-model="selectedTopic.data.Body"
      ></textarea>
    </div>
    <button>Submit</button>
  </form>
</template>

<script>
export default {
  name: "TopicEditor",
  props: {
    topic: Object,
  },
  emits: ["submit"],
  watch: {
    topic: function (newval) {
      this.selectedTopic = newval;
    },
  },
  data() {
    return {
      selectedTopic: this.topic,
    };
  },
  created() {
    this.selectedTopic = this.topic;
  },
  methods: {
    async editTopic(e) {
      e.preventDefault();
      console.log(this.selectedTopic);
      console.log(JSON.stringify(this.selectedTopic));
      await fetch("api/cta", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.selectedTopic),
      });
      this.$emit("submit", this.topic);
    },
  },
};
</script>

<style scoped>
form {
  border: 2px solid black;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.input-container {
  width: 100%;
  font-size: 1.5em;
  margin: 0px 10px;
}
</style>
